exports.components = {
  "component---src-containers-app-cart-index-js": () => import("./../../../src/containers/app/cart/index.js" /* webpackChunkName: "component---src-containers-app-cart-index-js" */),
  "component---src-containers-app-checkout-index-js": () => import("./../../../src/containers/app/checkout/index.js" /* webpackChunkName: "component---src-containers-app-checkout-index-js" */),
  "component---src-containers-app-checkout-kindergarten-index-js": () => import("./../../../src/containers/app/checkout/kindergarten/index.js" /* webpackChunkName: "component---src-containers-app-checkout-kindergarten-index-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cvxvwfewfw-test-checkout-js": () => import("./../../../src/pages/cvxvwfewfw-test-checkout.js" /* webpackChunkName: "component---src-pages-cvxvwfewfw-test-checkout-js" */),
  "component---src-pages-gutscheine-danke-js": () => import("./../../../src/pages/gutscheine/danke.js" /* webpackChunkName: "component---src-pages-gutscheine-danke-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

