// use cookies?


export const isBrowser = () => typeof window !== "undefined";

export const getCustomer = () =>
  isBrowser() && window.localStorage.getItem("heddaCustomer")
    ? JSON.parse(window.localStorage.getItem("heddaCustomer"))
    : {}

export const getCustomerToken = () => {
  const customer = getCustomer();
  return customer.authenticationToken;
}

export const setCustomer = data => {
  window.localStorage.setItem("heddaCustomer", JSON.stringify(data));
};

export const isLoggedIn = () => {
  const customer = getCustomer();
  return !!customer.authenticationToken;
}

export const login = customer => {
  isBrowser() && window.localStorage.setItem("heddaCustomer", JSON.stringify(customer));
}

export const logout = (client, callback) => {
  client.cache.reset();
  setCustomer({});
  callback();
}
