import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/client';
import { client } from './client';

export const wrapRootElement = ({ element }) => (
  <ApolloProviderHooks client={client}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </ApolloProviderHooks>
);
