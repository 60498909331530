import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { getCustomerToken } from '../services/auth';

const token = getCustomerToken();

export const client = new ApolloClient({
  uri: process.env.CASHIER_GRAPHQL_API_ENDPOINT,
  fetch,
  request: operation => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      },
    });
  }
})
